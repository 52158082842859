.leave-modal {
  .popup {
    width: 90%;
    background-color: #fff6e9;
    padding: 2rem;
    // border: 2px solid #251e3e;
  }
  .title {
    font-size: 2rem;
    text-transform: uppercase;
    color: #037bff;
    font-weight: bold;
  }
  .table-wrapper {
    margin-top: 2rem;
    table {
      width: 100%;
      th, td {
        border: 1px solid #eee;
        padding: 4px;
      }
    }
  }
  .rt-tr-group {
    cursor: pointer;
  }
  .rt-thead {
    font-weight: bold;
    color: #222;
  }
  .calendar {
    // min-height: 70rem !important;
  }
  .close-btn {
    margin-top: 2rem;
    cursor: pointer;
  }
}