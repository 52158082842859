.intern-detail-page {
  margin-top: 1rem;
  font-size: 1.4rem;
  .title {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 1rem;
    width: 100%;
  }
  .intern-detail {
    background-color: #fff;
    padding: 2rem;
    .ticket-data-row {
      margin-top: 2rem;
      .label {
        font-weight: bold;
        // color: #854442;
      }
      .value {
        background-color: #eee;
        padding: 6px;
        border: 1px solid #aaa;
        border-radius: 5px;
        
        &:focus {
          display: block;
          border-color: #66afe9;
          outline: 0;
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        }
        .obj-standalone {
          margin-bottom: 2rem;
          border-bottom: 1px solid #bbb;
          .obj-value {
            padding-left: 2rem;
          }
        }
      }
    }
  }
  .btns-wrapper {
    // background-color: #fff;
    padding: 2rem;
    .btn-left, .btn-right {
      margin: auto;
      text-align: center;
    }
    button {
      font-size: 1.4rem;
    }
  }
  .histories {
    background-color: #fff;
    margin-top: 1rem;
    padding: 2rem;
    .top {
      text-align: center;
      font-weight: bold;
    }
    table {
      margin: auto;
      margin-top: 1rem;
      thead tr {
        font-weight: bold;
      }
      td {
        border: 1px solid #eee;
        padding: 1rem;
      }
    }
  }
  .assets {
    padding: 2rem;
    .top {
      text-align: center;
      font-weight: bold;
    }
    .asset {
      margin-bottom: 1rem;
      .type {
        font-weight: bold;
        color: #851e3e;
      }
      label {
        min-width: 10rem;
        color: #03396c;
      }
      .border-bottom {
        height: 1px;
      }
    }
  }
}