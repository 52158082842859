@media print {
  @page {
    margin: 10mm !important; // margin for each printed piece of paper
  }
  html,
  body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  .pagebreak {
    clear: both;
    page-break-before: always !important;
    display: block;
  }
  body {
    background-color: #fff !important;
    margin: 0;
  }
  .avoid-break {
    break-inside: avoid !important;
  }
  #header,
  #footer {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print *,
  .div-to-print {
    visibility: visible;
    max-width: 100% !important;
  }
  // #section-to-print {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
  .no-print {
    display: none;
  }
  .ticket-detail-page {
    visibility: hidden;
    max-width: 100% !important;
  }
  .ticket-body {
    padding-top: 0rem !important;
    margin-top: 0rem !important;
  }
  .print-footer {
    position: fixed !important;
    bottom: 1mm !important;
    left: 1mm !important;
    &.ticket-name-26,
    &.ticket-name-27,
    &.ticket-name-32 {
      font-size: 3rem !important;
    }
  }
  .contract-30,
  .contract-31 {
    max-width: 100% !important;
  }
}
