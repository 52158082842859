.search-ticket-page {
  text-align: center;
  padding: 1rem;
  .search-form {
    text-align: left;
    margin: 1rem;
    .form-common {
      margin-top: 0rem;
    }
    .no-border {
      margin-bottom: 0 !important;
    }
    .select-wrapper {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
  .search-result {
    margin: 1rem;
    .result-count {
      text-align: left;
    }
    .export-csv {
      text-align: right;
    }
  }
  .group-search-result {
    background: #fff;
    padding: 2rem;
    margin-top: 1rem;
    overflow-x: auto;
    table {
      width: 40%;
    }
    table, th, td {
      border: 1px solid #eee;
      text-align: left;
      padding: .5rem;
    }
  }
}