.search-box-wrapper {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  .advanced-search {
    text-align: left;
    margin-left: 50%;
    transform: translateX(-50%);
    font-size: 1.4rem;
    font-style: italic;
    a {
      color: #4e2828;
      text-decoration: underline;
    }
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  margin: auto;
  width: 50%;
  height: 50px;
  padding: 10px 20px;
  // font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  margin:auto;
  top: 51px;
  width: 50%;
  border: 1px solid #aaa;
  background-color: #fff;
  // font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  text-align: left;
  position: absolute;
  transform: translateX(50%);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
