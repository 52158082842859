.profile-page {
  margin-top: 2rem;
  font-size: 1.4rem;
  .date-calculator {
    display: none;
    width: 152px;
    height: 152px;
    background-color: #fff;
    border: 10px solid #2c203e;
    border-radius: 2rem;
    text-align: center;
    .top {
      background-color: #fe774a;
      height: 4rem;
      border-bottom: 10px solid #2c203e;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
    .date {
      background-color: #fff;
      font-size: 3rem;
      color: #2c203e;
      font-weight: bold;
    }
    .calculator {
      background-color: #fff;
      font-size: 1.6rem;
    }
  }
}