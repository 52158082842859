.tasks-page {
  margin-top: 2rem;
  background-color: #fff;
  font-size: 1.4rem;
  .side-menu {
    border-right: 1px solid #eee;
    font-size: 1.3rem;
    padding: 1rem;
    .nav-link {
      &.active {
        background-color: #007bff;
        color: #fff;
        border-radius: 20px;
      }
    }
  }
  .body-content {
    padding: 2rem;
    text-align: center;
  }
}
.task-detail {
  margin-bottom: 4rem;
  background-color: #f9caa7;
  font-size: 1.4rem;
  &.complete {
    background-color:#d0e1f9;
    .task-title {
      background-color: #84c1ff;
    }
  }
  .task-title {
    background-color: #fe9c8f;
    padding: 1rem;
    font-size: 1.8rem;
  }
  .task-content {
    padding: 2rem;
    text-align: left;
  }
  .confirmation-form {
    padding-bottom: 1rem;
    text-align: center;
    .submit-btn {
      margin-top: 0rem;
      button {
        font-size: 1.4rem;
      }
    }
  }
}