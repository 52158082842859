.form-30 {
  input[name="signingDate"] {
    width: 8rem !important;
  }
  input[type="text"] {
    min-width: 12rem;
  }
  .cover-page {
    text-align: center;
    width: 100%;
    position: relative;
    &:after {
      content: "INTERNAL";
      width: 100%;
      height: 100%;
      z-index: 0;
      transform: rotate(-30deg);
      position: absolute;
      top: 50rem;
      left: 5rem;
      height: 30rem;
      font-size: 16rem;
      color: #eee;
      opacity: 0.1;
      font-weight: bold;
      font-size: 160px;
      -webkit-text-stroke-width: 5.8px;
      -webkit-text-stroke-color: #B80000;
      -webkit-text-fill-color: #fafafa;
      letter-spacing: 6px;
    }
    .template-info {
      font-style: italic;
      text-align: right;
      font-weight: bold;
    }
    .title {
      font-weight: bold;
    }
    .sub-title {
      font-style: italic;
      color: #305495;
      font-weight: bold;
    }
    .title-hl {
      color: red;
      text-decoration: underline;      
      background: yellow;
    }
    .sub-title-hl {
      color: red;
      font-weight: bold;
      background: yellow;
      width: fit-content;
      margin: auto;
    }
    .cover-table-wrapper {
      width: 100%;
      text-align: left;
      .text-italic {
        color: #305495;
      }
      table {
        width: 100%;
        line-height: 1.7em;
        &.table-inside {
          th, td {
            border: none;
            
          }
          td:first-child {
            border-right: 1px solid #000;
          }
        }
        th, td {
          border: 1px solid #000;
          padding: 1px 4px !important;
        }
        th {
          background-color: #bdd7ee;
        }
        tr {
          &.tr-hl {
            background-color: #e1f0d9;
          }
        }
        td:first-child {
          width: 40%;
        }
      }
    }
  }
  .partners-table {
    .first-row {
      text-transform: uppercase;
      font-weight: bold;
    }
    td:nth-child(1) {
      min-width: 10rem;
    }
    td:nth-child(2) {
      padding-left: 3rem;
    }
    td:nth-child(3) {
      padding-left: 3rem;
    }
  }
  .contract-point {
    font-weight: bold;
  }
  .dichvu-wrapper {
    margin-top: -3rem;
    .checkbox-input-wrapper {
      label {
        font-style: italic;
      }
    }
  }
  .checkbox-input-wrapper {
    input {
      margin-right: 2rem;
    }
    label {
      font-weight: bold;
    }
  }
  .gtgt {
    display: inline-block;
  }
  .dichvu-table, .chatluong-dichvu-table {
    width: 100%;
    margin-bottom: 2rem;
    table {
      width: 100%;
      thead {
        tr th {
          background-color: #e1f0d9;
          padding: 4px;
          border: 1px solid #000;
          text-align: center;
        }
      }
      tbody {
        tr td {
          padding: 4px;
          text-align: center;
          .chi-tiet-dich-vu {
            width: 100%;
            min-width: 60rem;
          }
          input {
            width: 100%;
            border: none;
          }
          border: 1px solid #000;
          &.sum-input {
            font-weight: bold;
            text-align: center;
          }
        }
        tr td:nth-child(1) {
          text-align: center;
        }
        tr td:nth-child(5) {
          width: 20rem;
          text-align: right;
        }
        tr td:last-child {
          width: 10rem;
          button {
            margin: 0rem 1rem;
          }
        }
      }
    }
  }
  .chatluong-dichvu-table {
    tbody tr td:nth-child(1) {
      width: 10rem;
    }
    tbody tr td:nth-child(3) {
      width: 20rem;
    }
  }
  .thoi-han-khong-canh-tranh-table {
    width: 100%;
    table {
      width: 100%;
      tr {
        &.row-hl {
          background-color: #e1f0d9;
        }
      }
      tr td  {
        padding: 4px;
        textarea {
          width: 100%;
          border: none;
        }
        border: 1px solid #000;
      }
      td:nth-child(1) {
        text-align: center;
        width: 5rem;
        input {
          margin-right: 0rem;
        }
      }
    }
  }
  .cac-dieu-khoan-chung {
    li {
      list-style: lower-alpha;
    }
  }
  .sign-section {
    margin-top: 1rem;
    margin-bottom: 15rem;
    .sign-title, .signer-name, .signer-position {
      font-weight: bold;
      text-align: center;
    }
    .signer-name {
      margin-top: 6rem;
    }
  }
  .fixed-content-section {
    .point {
      text-transform: uppercase;
      font-weight: bold;
    }
    .point-point {
      font-style: italic;
      font-weight: bold;
    }
    .list-abc {
      list-style: lower-alpha;
    }
    p {
      font-family: 'Montserrat', sans-serif;
    }
  }
  .submit-btn {
    text-align: center;
    .btn {
      font-size: 1.6rem;
    }
  }
}