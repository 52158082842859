html {
  height: 100%;
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  height: 100%;
  position: relative;
  font-size: 1.6rem;
  color: #fff;
  font-family: 'Montserrat', sans-serif !important;
  background-size: cover;
  background-color: #eee !important;
  color: #363a45;
}

img {
  max-width: 100%;
}
#root {
  position: relative;
  min-height: 100%;
}
a {
  &:hover {
    text-decoration: none;
  }
}
.swal2-popup {
  font-size: 100% !important;
  z-index: 10;
  &.homepage-popup {
    .swal2-title {
      color: #f04145;
    }
  }
  .highlighted {
    color: #f04145;
    font-weight: bold;
  }
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  padding-left: 1rem;
  padding-right: 1rem;
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.login-area {
  padding-top: 2rem;
  a {
    margin-left: .3rem;
    margin-right: .3rem;
    float: left;
  }
  small {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    text-overflow: ellipsis;
  }
}

#main-body {
  position: relative;
  min-height: 95vh;
  padding-bottom: 15rem;
}

.main-home {
  margin-top: 3rem;
}

.card {
  border: 1px solid #aaaaaa5c;
  background-color: #fff;
  padding: 2rem;
  margin-bottom: 1rem;
}
.error-message-page {
  margin-top: 2rem;
  vertical-align: middle;
  .error-message {
    text-align: center;
    font-size: 2rem;
    color: #fff;
  }
}

.validation-error, .error {
  color: red;
}

.hl {
  color: red;
  font-weight: bold;
}

.react-datepicker {
  font-family: $roboto !important;
  font-size: 1.4rem !important;
}
.react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__current-month {
  font-size: 1.4rem !important;
}
.react-datepicker__month-container {
  width: 20rem;
}
.react-datepicker__day, .react-datepicker__day-name, .react-datepicker__time-name {
  width: 2.5rem !important;
}
input[type="radio"] {
  margin-right: 1rem;
  margin-top: -0.4rem;
  vertical-align: middle;
  cursor: pointer;
}
textarea {
  border: 1px solid #d2d2d2;
}

.__react_component_tooltip {
  overflow: hidden;
  background-color: #e7eff6 !important;
  color: #333 !important;
  padding: 10px 20px !important;
  border: 1px solid #ddd !important;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  &.show {
    opacity: 0.99 !important;
  }
}


.main-nodes-wrapper {
  .node-138 {
    .node-name {
      font-size: 1.4rem !important;
    }
    .node-description {
      font-size: 1.5rem !important;
    }
  }
}
.first-level-node {
  min-height: 24rem;
  text-align: center;
  cursor: pointer;
  margin-top: 2rem;
  background-color: #fff;
  position: relative;
  border: 1px solid #eee;
  z-index: 1;
  padding: 3rem;
  transition: transform .2s ease-in-out;
  border: 1px solid #aaaaaa5c !important;
  .node-name {
    color: #230b9e;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1rem;
  }
  .node-description {
    color: #4e2828;
    margin-top: 1rem;
  }
  &:hover {
    background-color: #b3cde0;
  }
}


.notification-table {
  width: 100%;
  tr {
    width: 100%;
  }
  tr td {
    border: 1px solid #eee;
    padding: 4px;
  }
}