.page-alerts {
  text-align: center;
  .alert {
    margin-bottom: 0px !important;
  }
  .alert-danger {
    background-color: #f04145 !important;
    color: #fff !important;
    a {
      color: #fff !important;
    }
  }
}

.carousel-item {
  background-color: #f04145;
  min-height: 10rem !important;
  .carousel-caption  {
    text-align: left;
    height: 10rem;
    padding: 0rem !important;
    bottom: 0rem !important;
    .new-icon {
      text-align: right;
      margin: auto;
      margin-top: 1rem;
      animation: shrink 0.2s ease-in-out infinite;
    }
    .noti-right {
      padding-top: 1rem;
      .text {
        font-size: 2rem;
      }
      .noti-url {
        font-size: 1.2rem;
        font-style: italic;
        a {
          color: #dedede;
          text-decoration: underline;
        }
      }
      .created-at {
        margin-top: 1rem;
        font-size: 1.2rem;
        .date {
          font-size: 1.5rem;
        }
      }
      .noti-index {
        position: absolute;
        bottom: 0rem;
        right: 0rem;
      }
    }
  }
  
}