.contact-us-page {
  .questions-wrapper {
    width: 100% !important;
    label {
      cursor: pointer !important;
    }
  }
  .contacts-wrapper {
    margin-top: 2rem;
    .title {
      background-color: #0070BA;
      color: #fff;
      padding: 1rem;
    }
    .fallback {
      .title {
        background: #f18585;
        color: #000;
        text-align: left;
        border-radius: 5px;
        .show-fallback-btn {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .fallback-contact {
        display: none;
        &.shown {
          display: block;
        }
      }
    }
  }
  .contact {
    table {
      width: 100%;
      margin: 1rem 0rem;
    }
    table tr td {
      border: 1px solid #22292e;
      padding: 1rem;
      text-align: left;
      padding-left: 3rem;
    }
  }
}