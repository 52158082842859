.contract-40 {
  .text-italic {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  font-size: 1.7rem;
}

.driver-popover-description {
  min-height: 100px !important;
}
