.normal-search-result {
  .search-result {
    padding-top: 4.4rem;
    .results {
      .top {
        text-align: center;
        font-size: 2rem;
        color: #0070BA;
      }
      ul {
        list-style-type: none;
        margin-left: -18px;
        margin-top: 2rem;
        li {
          font-size: 2rem;
          a {
            color: #2C2E2F;
            &:hover {
              color: #0070BA;
              text-decoration: underline;
            }
          }
          &:before {
            content: "-";
            position: absolute;
            margin-left: -20px;
          }
        }
      }
    }
  }
}
