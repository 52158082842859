.contract-composer-page {
  margin: auto;
  .top {
    border-radius: 10px;
    margin-bottom: 1rem;
    width: 100%;
    .title {
      background-color: #0070BA;
      text-transform: uppercase;
      font-size: 2.4rem;
      color: #fff;
      padding: 1rem;
      text-align: center;
    }
    .description {
      background-color: #fff;
      padding: 2rem;
    }
    .editable-div {
      background-color: lightblue;
      color: lightblue;
      min-width: 10rem;
      margin-left: 4px;
    }
  }
  .form-composer-wrapper {
    background-color: #fff;
    padding: 1.5rem 4rem;
  }
  .tiny-input {
    input, textarea {
      border: none;
      border-bottom: 1px solid #d2d2d2;
      box-shadow: none;
      outline: none;
      width: auto;
      background-color: lightblue;
    }
    textarea {
      // min-width: 50rem;
      // resize: both !important;
      &.big-textarea {
        min-width: 80rem;
        border: 1px solid #d2d2d2;
      }
    }
  }
  .tiny-select {
    display: inline-block;
    margin-right: 1rem;
    select {
      height: 3.5rem;  
      &.gender-select {
        width: 10rem;
      }
    }
    
  }
  .text-italic {
    font-style: italic;
  }
  .text-strong {
    font-weight: bold;
  }
  .text-uppercase {
    text-transform: uppercase;
  }
  .text-align-left {
    text-align: left;
  }
  .text-align-center {
    text-align: center;
  }
  ul {
    &.list-i {
      list-style: lower-roman;
    }
    &.list-abc {
      list-style: lower-alpha;
    }
  }
  .form-name {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 1rem;
  }
}