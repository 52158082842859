.form-common {
  max-width: 700px;
  margin: auto;
  margin-top: 2rem;
  .card {
    &.no-border {
      border: none;
    }
  }
  .top {
    border-radius: 10px;
    margin-bottom: 1rem;
    .title {
      background-color: #0070BA;
      text-transform: uppercase;
      font-size: 2.4rem;
      color: #fff;
      padding: 1rem;
      text-align: center;
    }
    .description {
      background-color: #fff;
      padding: 2rem;
      ul {
        list-style-type: none;
        margin-left: -18px;
        margin-top: 0rem;
        li {
          font-size: 1.6rem;
          a {
            color: #2C2E2F;
            &:hover {
              color: #0070BA;
              text-decoration: underline;
            }
          }
          &:before {
            content: "-";
            position: absolute;
            margin-left: -20px;
          }
        }
      }
    }
  }
  .form-divider-text {
    padding: 0px !important;
    .title {
      background-color: #fe9c8f;
      padding: 1rem;
      text-align: center;
      color: #333;
      font-size: 1.6rem;
    }
    .description {
      background-color: #fff;
      padding: 1rem;
    }
  }
  
  input {
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid #d2d2d2;
    box-shadow: none;
    outline: none;
  }
  textarea {
    outline: none;
  }
  .form-group {
    label {
      font-weight: 500;
      color: #854442;
    }
    .textarea-description {
      font-style: italic;
      font-size: 1.4rem;
    }
  }
  .my-radio-list {
    .title-label {
      font-weight: 500;
      color: #854442;
    }
    label {
      font-weight: normal ;
      color:#363a45;
    }
  }
    
  .my-select-list {
    .title-label {
      font-weight: 500;
      color: #854442;
    }
    select {
      min-height: 3.8rem;
    }
    .other-text {
      margin-top: 2rem;
    }
  }
  .submit-btn {
    text-align: center;
    margin-top: 2rem;
    button {
      font-size: 2rem;
      padding: 4px 12px;
      cursor: pointer;
      text-transform: uppercase;
      &:hover {
        color: #fff;
      }
    }
  }

  .mini-form {
    .mini-form-title {
      font-weight: 500;
      color: #854442;
    }
    .no-border { 
      padding: 0rem;
      label {
        color: #005b96;
        font-weight: normal;
      }
    }
    .remove-btn {
      width: 10rem;
      margin: auto;
    }
  }
  .btn {
    font-size: 1.4rem;
  } 

  .input-suggestions {
    .suggestions {
      margin-top: 1rem;
      ul {
        padding: 0px;
      }
      li {
        cursor: pointer;
        list-style-type: none;
        margin: 2px 0px;
        padding: 2px;
        font-style: italic !important;
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .json-data-wrapper {
    background-color: #fff;
    padding: 2rem;
    overflow-x: scroll;
    table {
      background-color: #b3cde0;
      th, td {
        padding: 8px;
        border: 1px solid #eee;
      }
      thead > tr > th {
        font-weight: normal;
      }
    }
  }

  .my-multiple-checkbox {
    .checkbox-input-wrapper {
      label {
        margin-left: .5rem;
      }
    }
  }
}