.access-denied {
  padding-top: 7rem;
  &-wrapper {
    margin:auto;
    margin-top: 7rem;
    width: 50%;
  }
  .error-message {
    font-size: 3rem;
    color: red;
  }

}