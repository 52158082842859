.form-38 {
  .checkbox-input-wrapper {
    display: flex;
    flex-direction: row;
    margin: 6px 0px;
    label {
      color: #363a45 !important;
      font-weight: normal !important;
    }
  }
}