.advance-search-page {
  text-align: center;
  max-width: 95%;
  margin: auto;
  margin-top: 1rem;
  .search-form {
    text-align: left;
    margin: 1rem;
    .form-common {
      margin-top: 0rem;
    }
    .no-border {
      margin-bottom: 0 !important;
    }
    .select-wrapper {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
  .search-result {
    margin: 1rem;
    &-title {
      background-color: #00aedb;
      padding: 1rem;
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
    }
    &-content {
      margin-top: 1rem;
      .contract-count, .payment-count {
        margin-top: 1rem;
        .number {
          font-weight: bold;
          font-size: 2rem;
        }
      }
      .items {
        background-color: #fff;
        margin-top: 2rem;
        .title {
          background-color: #8dbdff;
          color: #000;
          padding: 1rem;
          text-transform: uppercase;
          font-weight: bold;
        }
        .content {
          padding: 2rem;
          table {
            width: 100%;
            background: ghostwhite;
            tr > td {
              border: 1px solid #777;
              padding: 4px;
              text-align: left;
            }
          }
        }
      }
    }
  }

}