.contract-31 {
  float:none !important;
  line-height: 2em;
  font-size: 1.348rem;
  padding: 0rem 3rem;
  max-width: 50%;
  background-color: #fff;
  .cover-page {
    font-size: 1.4rem;
    table {
      width: 100%;
    }
  }
  .chatluong-dichvu-table {
    table {
      tr {
        td:nth-child(2) {
          max-width: 45rem;
          overflow-wrap: break-word;
        }
        td:nth-child(3) {
          min-width: 45rem;
        }
      }
    }
  }
  .dichvu-result {
    .dichvu-text {
      margin-left: 1rem;
      font-weight: bold;
    }
  }
}