@import "fa/variables";

@font-face {
    font-family: 'icon';
    src: url('/fonts/fontawesome-webfont.eot?v=1');
    src: 
        url('/fonts/fontawesome-webfont.eot?#iefix&v=1') format('embedded-opentype'),
        url('/fonts/fontawesome-webfont.woff2?v=1') format('woff2'),
        url('/fonts/fontawesome-webfont.woff?v=1') format('woff'),
        url('/fonts/fontawesome-webfont.ttf?v=1') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


[class*="icon-"]:before {
    font-family: "icon";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    font-size: inherit;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-0_5x{font-size:0.5em;}
.icon-1_5x{font-size:1.5em;}
.icon-2x{font-size:2em;}
.icon-2_5x{font-size:2.5em;}

.icon-reflect {
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.icon-rotate-90 {   
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform:rotate(90deg);
}

.icon-rotate {
    &:before {
        -webkit-animation: spin 1.5s linear infinite;
        -moz-animation: spin 1.5s linear infinite;
        animation: spin 1.5s linear infinite;
    }
}

.icon-loading {
    &:before {
        -webkit-animation:spin .6s ease-in-out infinite;
        -moz-animation:spin .6s ease-in-out infinite;
        animation:spin .6s steps(8,end) infinite;
    }
}

.icon-glass:before { content: $fa-var-glass; }
.icon-music:before { content: $fa-var-music; }
.icon-search:before { content: $fa-var-search; }
.icon-envelope-o:before { content: $fa-var-envelope-o; }
.icon-heart:before { content: $fa-var-heart; }
.icon-star:before { content: $fa-var-star; }
.icon-star-o:before { content: $fa-var-star-o; }
.icon-user:before { content: $fa-var-user; }
.icon-film:before { content: $fa-var-film; }
.icon-th-large:before { content: $fa-var-th-large; }
.icon-th:before { content: $fa-var-th; }
.icon-th-list:before { content: $fa-var-th-list; }
.icon-check:before { content: $fa-var-check; }
.icon-remove:before,
.icon-close:before,
.icon-times:before { content: $fa-var-times; }
.icon-search-plus:before { content: $fa-var-search-plus; }
.icon-search-minus:before { content: $fa-var-search-minus; }
.icon-power-off:before { content: $fa-var-power-off; }
.icon-signal:before { content: $fa-var-signal; }
.icon-gear:before,
.icon-cog:before { content: $fa-var-cog; }
.icon-trash-o:before { content: $fa-var-trash-o; }
.icon-home:before { content: $fa-var-home; }
.icon-file-o:before { content: $fa-var-file-o; }
.icon-clock-o:before { content: $fa-var-clock-o; }
.icon-road:before { content: $fa-var-road; }
.icon-download:before { content: $fa-var-download; }
.icon-arrow-circle-o-down:before { content: $fa-var-arrow-circle-o-down; }
.icon-arrow-circle-o-up:before { content: $fa-var-arrow-circle-o-up; }
.icon-inbox:before { content: $fa-var-inbox; }
.icon-play-circle-o:before { content: $fa-var-play-circle-o; }
.icon-rotate-right:before,
.icon-repeat:before { content: $fa-var-repeat; }
.icon-refresh:before { content: $fa-var-refresh; }
.icon-list-alt:before { content: $fa-var-list-alt; }
.icon-lock:before { content: $fa-var-lock; }
.icon-flag:before { content: $fa-var-flag; }
.icon-headphones:before { content: $fa-var-headphones; }
.icon-volume-off:before { content: $fa-var-volume-off; }
.icon-volume-down:before { content: $fa-var-volume-down; }
.icon-volume-up:before { content: $fa-var-volume-up; }
.icon-qrcode:before { content: $fa-var-qrcode; }
.icon-barcode:before { content: $fa-var-barcode; }
.icon-tag:before { content: $fa-var-tag; }
.icon-tags:before { content: $fa-var-tags; }
.icon-book:before { content: $fa-var-book; }
.icon-bookmark:before { content: $fa-var-bookmark; }
.icon-print:before { content: $fa-var-print; }
.icon-camera:before { content: $fa-var-camera; }
.icon-font:before { content: $fa-var-font; }
.icon-bold:before { content: $fa-var-bold; }
.icon-italic:before { content: $fa-var-italic; }
.icon-text-height:before { content: $fa-var-text-height; }
.icon-text-width:before { content: $fa-var-text-width; }
.icon-align-left:before { content: $fa-var-align-left; }
.icon-align-center:before { content: $fa-var-align-center; }
.icon-align-right:before { content: $fa-var-align-right; }
.icon-align-justify:before { content: $fa-var-align-justify; }
.icon-list:before { content: $fa-var-list; }
.icon-dedent:before,
.icon-outdent:before { content: $fa-var-outdent; }
.icon-indent:before { content: $fa-var-indent; }
.icon-video-camera:before { content: $fa-var-video-camera; }
.icon-photo:before,
.icon-image:before,
.icon-picture-o:before { content: $fa-var-picture-o; }
.icon-pencil:before { content: $fa-var-pencil; }
.icon-map-marker:before { content: $fa-var-map-marker; }
.icon-adjust:before { content: $fa-var-adjust; }
.icon-tint:before { content: $fa-var-tint; }
.icon-edit:before,
.icon-pencil-square-o:before { content: $fa-var-pencil-square-o; }
.icon-share-square-o:before { content: $fa-var-share-square-o; }
.icon-check-square-o:before { content: $fa-var-check-square-o; }
.icon-arrows:before { content: $fa-var-arrows; }
.icon-step-backward:before { content: $fa-var-step-backward; }
.icon-fast-backward:before { content: $fa-var-fast-backward; }
.icon-backward:before { content: $fa-var-backward; }
.icon-play:before { content: $fa-var-play; }
.icon-pause:before { content: $fa-var-pause; }
.icon-stop:before { content: $fa-var-stop; }
.icon-forward:before { content: $fa-var-forward; }
.icon-fast-forward:before { content: $fa-var-fast-forward; }
.icon-step-forward:before { content: $fa-var-step-forward; }
.icon-eject:before { content: $fa-var-eject; }
.icon-chevron-left:before { content: $fa-var-chevron-left; }
.icon-chevron-right:before { content: $fa-var-chevron-right; }
.icon-plus-circle:before { content: $fa-var-plus-circle; }
.icon-minus-circle:before { content: $fa-var-minus-circle; }
.icon-times-circle:before { content: $fa-var-times-circle; }
.icon-check-circle:before { content: $fa-var-check-circle; }
.icon-question-circle:before { content: $fa-var-question-circle; }
.icon-info-circle:before { content: $fa-var-info-circle; }
.icon-crosshairs:before { content: $fa-var-crosshairs; }
.icon-times-circle-o:before { content: $fa-var-times-circle-o; }
.icon-check-circle-o:before { content: $fa-var-check-circle-o; }
.icon-ban:before { content: $fa-var-ban; }
.icon-arrow-left:before { content: $fa-var-arrow-left; }
.icon-arrow-right:before { content: $fa-var-arrow-right; }
.icon-arrow-up:before { content: $fa-var-arrow-up; }
.icon-arrow-down:before { content: $fa-var-arrow-down; }
.icon-mail-forward:before,
.icon-share:before { content: $fa-var-share; }
.icon-expand:before { content: $fa-var-expand; }
.icon-compress:before { content: $fa-var-compress; }
.icon-plus:before { content: $fa-var-plus; }
.icon-minus:before { content: $fa-var-minus; }
.icon-asterisk:before { content: $fa-var-asterisk; }
.icon-exclamation-circle:before { content: $fa-var-exclamation-circle; }
.icon-gift:before { content: $fa-var-gift; }
.icon-leaf:before { content: $fa-var-leaf; }
.icon-fire:before { content: $fa-var-fire; }
.icon-eye:before { content: $fa-var-eye; }
.icon-eye-slash:before { content: $fa-var-eye-slash; }
.icon-warning:before,
.icon-exclamation-triangle:before { content: $fa-var-exclamation-triangle; }
.icon-plane:before { content: $fa-var-plane; }
.icon-calendar:before { content: $fa-var-calendar; }
.icon-random:before { content: $fa-var-random; }
.icon-comment:before { content: $fa-var-comment; }
.icon-magnet:before { content: $fa-var-magnet; }
.icon-chevron-up:before { content: $fa-var-chevron-up; }
.icon-chevron-down:before { content: $fa-var-chevron-down; }
.icon-retweet:before { content: $fa-var-retweet; }
.icon-shopping-cart:before { content: $fa-var-shopping-cart; }
.icon-folder:before { content: $fa-var-folder; }
.icon-folder-open:before { content: $fa-var-folder-open; }
.icon-arrows-v:before { content: $fa-var-arrows-v; }
.icon-arrows-h:before { content: $fa-var-arrows-h; }
.icon-bar-chart-o:before,
.icon-bar-chart:before { content: $fa-var-bar-chart; }
.icon-twitter-square:before { content: $fa-var-twitter-square; }
.icon-facebook-square:before { content: $fa-var-facebook-square; }
.icon-camera-retro:before { content: $fa-var-camera-retro; }
.icon-key:before { content: $fa-var-key; }
.icon-gears:before,
.icon-cogs:before { content: $fa-var-cogs; }
.icon-comments:before { content: $fa-var-comments; }
.icon-thumbs-o-up:before { content: $fa-var-thumbs-o-up; }
.icon-thumbs-o-down:before { content: $fa-var-thumbs-o-down; }
.icon-star-half:before { content: $fa-var-star-half; }
.icon-heart-o:before { content: $fa-var-heart-o; }
.icon-sign-out:before { content: $fa-var-sign-out; }
.icon-linkedin-square:before { content: $fa-var-linkedin-square; }
.icon-thumb-tack:before { content: $fa-var-thumb-tack; }
.icon-external-link:before { content: $fa-var-external-link; }
.icon-sign-in:before { content: $fa-var-sign-in; }
.icon-trophy:before { content: $fa-var-trophy; }
.icon-github-square:before { content: $fa-var-github-square; }
.icon-upload:before { content: $fa-var-upload; }
.icon-lemon-o:before { content: $fa-var-lemon-o; }
.icon-phone:before { content: $fa-var-phone; }
.icon-square-o:before { content: $fa-var-square-o; }
.icon-bookmark-o:before { content: $fa-var-bookmark-o; }
.icon-phone-square:before { content: $fa-var-phone-square; }
.icon-twitter:before { content: $fa-var-twitter; }
.icon-facebook-f:before,
.icon-facebook:before { content: $fa-var-facebook; }
.icon-github:before { content: $fa-var-github; }
.icon-unlock:before { content: $fa-var-unlock; }
.icon-credit-card:before { content: $fa-var-credit-card; }
.icon-rss:before { content: $fa-var-rss; }
.icon-hdd-o:before { content: $fa-var-hdd-o; }
.icon-bullhorn:before { content: $fa-var-bullhorn; }
.icon-bell:before { content: $fa-var-bell; }
.icon-certificate:before { content: $fa-var-certificate; }
.icon-hand-o-right:before { content: $fa-var-hand-o-right; }
.icon-hand-o-left:before { content: $fa-var-hand-o-left; }
.icon-hand-o-up:before { content: $fa-var-hand-o-up; }
.icon-hand-o-down:before { content: $fa-var-hand-o-down; }
.icon-arrow-circle-left:before { content: $fa-var-arrow-circle-left; }
.icon-arrow-circle-right:before { content: $fa-var-arrow-circle-right; }
.icon-arrow-circle-up:before { content: $fa-var-arrow-circle-up; }
.icon-arrow-circle-down:before { content: $fa-var-arrow-circle-down; }
.icon-globe:before { content: $fa-var-globe; }
.icon-wrench:before { content: $fa-var-wrench; }
.icon-tasks:before { content: $fa-var-tasks; }
.icon-filter:before { content: $fa-var-filter; }
.icon-briefcase:before { content: $fa-var-briefcase; }
.icon-arrows-alt:before { content: $fa-var-arrows-alt; }
.icon-group:before,
.icon-users:before { content: $fa-var-users; }
.icon-chain:before,
.icon-link:before { content: $fa-var-link; }
.icon-cloud:before { content: $fa-var-cloud; }
.icon-flask:before { content: $fa-var-flask; }
.icon-cut:before,
.icon-scissors:before { content: $fa-var-scissors; }
.icon-copy:before,
.icon-files-o:before { content: $fa-var-files-o; }
.icon-paperclip:before { content: $fa-var-paperclip; }
.icon-save:before,
.icon-floppy-o:before { content: $fa-var-floppy-o; }
.icon-square:before { content: $fa-var-square; }
.icon-navicon:before,
.icon-reorder:before,
.icon-bars:before { content: $fa-var-bars; }
.icon-list-ul:before { content: $fa-var-list-ul; }
.icon-list-ol:before { content: $fa-var-list-ol; }
.icon-strikethrough:before { content: $fa-var-strikethrough; }
.icon-underline:before { content: $fa-var-underline; }
.icon-table:before { content: $fa-var-table; }
.icon-magic:before { content: $fa-var-magic; }
.icon-truck:before { content: $fa-var-truck; }
.icon-pinterest:before { content: $fa-var-pinterest; }
.icon-pinterest-square:before { content: $fa-var-pinterest-square; }
.icon-google-plus-square:before { content: $fa-var-google-plus-square; }
.icon-google-plus:before { content: $fa-var-google-plus; }
.icon-money:before { content: $fa-var-money; }
.icon-caret-down:before { content: $fa-var-caret-down; }
.icon-caret-up:before { content: $fa-var-caret-up; }
.icon-caret-left:before { content: $fa-var-caret-left; }
.icon-caret-right:before { content: $fa-var-caret-right; }
.icon-columns:before { content: $fa-var-columns; }
.icon-unsorted:before,
.icon-sort:before { content: $fa-var-sort; }
.icon-sort-down:before,
.icon-sort-desc:before { content: $fa-var-sort-desc; }
.icon-sort-up:before,
.icon-sort-asc:before { content: $fa-var-sort-asc; }
.icon-envelope:before { content: $fa-var-envelope; }
.icon-linkedin:before { content: $fa-var-linkedin; }
.icon-rotate-left:before,
.icon-undo:before { content: $fa-var-undo; }
.icon-legal:before,
.icon-gavel:before { content: $fa-var-gavel; }
.icon-dashboard:before,
.icon-tachometer:before { content: $fa-var-tachometer; }
.icon-comment-o:before { content: $fa-var-comment-o; }
.icon-comments-o:before { content: $fa-var-comments-o; }
.icon-flash:before,
.icon-bolt:before { content: $fa-var-bolt; }
.icon-sitemap:before { content: $fa-var-sitemap; }
.icon-umbrella:before { content: $fa-var-umbrella; }
.icon-paste:before,
.icon-clipboard:before { content: $fa-var-clipboard; }
.icon-lightbulb-o:before { content: $fa-var-lightbulb-o; }
.icon-exchange:before { content: $fa-var-exchange; }
.icon-cloud-download:before { content: $fa-var-cloud-download; }
.icon-cloud-upload:before { content: $fa-var-cloud-upload; }
.icon-user-md:before { content: $fa-var-user-md; }
.icon-stethoscope:before { content: $fa-var-stethoscope; }
.icon-suitcase:before { content: $fa-var-suitcase; }
.icon-bell-o:before { content: $fa-var-bell-o; }
.icon-coffee:before { content: $fa-var-coffee; }
.icon-cutlery:before { content: $fa-var-cutlery; }
.icon-file-text-o:before { content: $fa-var-file-text-o; }
.icon-building-o:before { content: $fa-var-building-o; }
.icon-hospital-o:before { content: $fa-var-hospital-o; }
.icon-ambulance:before { content: $fa-var-ambulance; }
.icon-medkit:before { content: $fa-var-medkit; }
.icon-fighter-jet:before { content: $fa-var-fighter-jet; }
.icon-beer:before { content: $fa-var-beer; }
.icon-h-square:before { content: $fa-var-h-square; }
.icon-plus-square:before { content: $fa-var-plus-square; }
.icon-angle-double-left:before { content: $fa-var-angle-double-left; }
.icon-angle-double-right:before { content: $fa-var-angle-double-right; }
.icon-angle-double-up:before { content: $fa-var-angle-double-up; }
.icon-angle-double-down:before { content: $fa-var-angle-double-down; }
.icon-angle-left:before { content: $fa-var-angle-left; }
.icon-angle-right:before { content: $fa-var-angle-right; }
.icon-angle-up:before { content: $fa-var-angle-up; }
.icon-angle-down:before { content: $fa-var-angle-down; }
.icon-desktop:before { content: $fa-var-desktop; }
.icon-laptop:before { content: $fa-var-laptop; }
.icon-tablet:before { content: $fa-var-tablet; }
.icon-mobile-phone:before,
.icon-mobile:before { content: $fa-var-mobile; }
.icon-circle-o:before { content: $fa-var-circle-o; }
.icon-quote-left:before { content: $fa-var-quote-left; }
.icon-quote-right:before { content: $fa-var-quote-right; }
.icon-spinner:before { content: $fa-var-spinner; }
.icon-circle:before { content: $fa-var-circle; }
.icon-mail-reply:before,
.icon-reply:before { content: $fa-var-reply; }
.icon-github-alt:before { content: $fa-var-github-alt; }
.icon-folder-o:before { content: $fa-var-folder-o; }
.icon-folder-open-o:before { content: $fa-var-folder-open-o; }
.icon-smile-o:before { content: $fa-var-smile-o; }
.icon-frown-o:before { content: $fa-var-frown-o; }
.icon-meh-o:before { content: $fa-var-meh-o; }
.icon-gamepad:before { content: $fa-var-gamepad; }
.icon-keyboard-o:before { content: $fa-var-keyboard-o; }
.icon-flag-o:before { content: $fa-var-flag-o; }
.icon-flag-checkered:before { content: $fa-var-flag-checkered; }
.icon-terminal:before { content: $fa-var-terminal; }
.icon-code:before { content: $fa-var-code; }
.icon-mail-reply-all:before,
.icon-reply-all:before { content: $fa-var-reply-all; }
.icon-star-half-empty:before,
.icon-star-half-full:before,
.icon-star-half-o:before { content: $fa-var-star-half-o; }
.icon-location-arrow:before { content: $fa-var-location-arrow; }
.icon-crop:before { content: $fa-var-crop; }
.icon-code-fork:before { content: $fa-var-code-fork; }
.icon-unlink:before,
.icon-chain-broken:before { content: $fa-var-chain-broken; }
.icon-question:before { content: $fa-var-question; }
.icon-info:before { content: $fa-var-info; }
.icon-exclamation:before { content: $fa-var-exclamation; }
.icon-superscript:before { content: $fa-var-superscript; }
.icon-subscript:before { content: $fa-var-subscript; }
.icon-eraser:before { content: $fa-var-eraser; }
.icon-puzzle-piece:before { content: $fa-var-puzzle-piece; }
.icon-microphone:before { content: $fa-var-microphone; }
.icon-microphone-slash:before { content: $fa-var-microphone-slash; }
.icon-shield:before { content: $fa-var-shield; }
.icon-calendar-o:before { content: $fa-var-calendar-o; }
.icon-fire-extinguisher:before { content: $fa-var-fire-extinguisher; }
.icon-rocket:before { content: $fa-var-rocket; }
.icon-maxcdn:before { content: $fa-var-maxcdn; }
.icon-chevron-circle-left:before { content: $fa-var-chevron-circle-left; }
.icon-chevron-circle-right:before { content: $fa-var-chevron-circle-right; }
.icon-chevron-circle-up:before { content: $fa-var-chevron-circle-up; }
.icon-chevron-circle-down:before { content: $fa-var-chevron-circle-down; }
.icon-html5:before { content: $fa-var-html5; }
.icon-css3:before { content: $fa-var-css3; }
.icon-anchor:before { content: $fa-var-anchor; }
.icon-unlock-alt:before { content: $fa-var-unlock-alt; }
.icon-bullseye:before { content: $fa-var-bullseye; }
.icon-ellipsis-h:before { content: $fa-var-ellipsis-h; }
.icon-ellipsis-v:before { content: $fa-var-ellipsis-v; }
.icon-rss-square:before { content: $fa-var-rss-square; }
.icon-play-circle:before { content: $fa-var-play-circle; }
.icon-ticket:before { content: $fa-var-ticket; }
.icon-minus-square:before { content: $fa-var-minus-square; }
.icon-minus-square-o:before { content: $fa-var-minus-square-o; }
.icon-level-up:before { content: $fa-var-level-up; }
.icon-level-down:before { content: $fa-var-level-down; }
.icon-check-square:before { content: $fa-var-check-square; }
.icon-pencil-square:before { content: $fa-var-pencil-square; }
.icon-external-link-square:before { content: $fa-var-external-link-square; }
.icon-share-square:before { content: $fa-var-share-square; }
.icon-compass:before { content: $fa-var-compass; }
.icon-toggle-down:before,
.icon-caret-square-o-down:before { content: $fa-var-caret-square-o-down; }
.icon-toggle-up:before,
.icon-caret-square-o-up:before { content: $fa-var-caret-square-o-up; }
.icon-toggle-right:before,
.icon-caret-square-o-right:before { content: $fa-var-caret-square-o-right; }
.icon-euro:before,
.icon-eur:before { content: $fa-var-eur; }
.icon-gbp:before { content: $fa-var-gbp; }
.icon-dollar:before,
.icon-usd:before { content: $fa-var-usd; }
.icon-rupee:before,
.icon-inr:before { content: $fa-var-inr; }
.icon-cny:before,
.icon-rmb:before,
.icon-yen:before,
.icon-jpy:before { content: $fa-var-jpy; }
.icon-ruble:before,
.icon-rouble:before,
.icon-rub:before { content: $fa-var-rub; }
.icon-won:before,
.icon-krw:before { content: $fa-var-krw; }
.icon-bitcoin:before,
.icon-btc:before { content: $fa-var-btc; }
.icon-file:before { content: $fa-var-file; }
.icon-file-text:before { content: $fa-var-file-text; }
.icon-sort-alpha-asc:before { content: $fa-var-sort-alpha-asc; }
.icon-sort-alpha-desc:before { content: $fa-var-sort-alpha-desc; }
.icon-sort-amount-asc:before { content: $fa-var-sort-amount-asc; }
.icon-sort-amount-desc:before { content: $fa-var-sort-amount-desc; }
.icon-sort-numeric-asc:before { content: $fa-var-sort-numeric-asc; }
.icon-sort-numeric-desc:before { content: $fa-var-sort-numeric-desc; }
.icon-thumbs-up:before { content: $fa-var-thumbs-up; }
.icon-thumbs-down:before { content: $fa-var-thumbs-down; }
.icon-youtube-square:before { content: $fa-var-youtube-square; }
.icon-youtube:before { content: $fa-var-youtube; }
.icon-xing:before { content: $fa-var-xing; }
.icon-xing-square:before { content: $fa-var-xing-square; }
.icon-youtube-play:before { content: $fa-var-youtube-play; }
.icon-dropbox:before { content: $fa-var-dropbox; }
.icon-stack-overflow:before { content: $fa-var-stack-overflow; }
.icon-instagram:before { content: $fa-var-instagram; }
.icon-flickr:before { content: $fa-var-flickr; }
.icon-adn:before { content: $fa-var-adn; }
.icon-bitbucket:before { content: $fa-var-bitbucket; }
.icon-bitbucket-square:before { content: $fa-var-bitbucket-square; }
.icon-tumblr:before { content: $fa-var-tumblr; }
.icon-tumblr-square:before { content: $fa-var-tumblr-square; }
.icon-long-arrow-down:before { content: $fa-var-long-arrow-down; }
.icon-long-arrow-up:before { content: $fa-var-long-arrow-up; }
.icon-long-arrow-left:before { content: $fa-var-long-arrow-left; }
.icon-long-arrow-right:before { content: $fa-var-long-arrow-right; }
.icon-apple:before { content: $fa-var-apple; }
.icon-windows:before { content: $fa-var-windows; }
.icon-android:before { content: $fa-var-android; }
.icon-linux:before { content: $fa-var-linux; }
.icon-dribbble:before { content: $fa-var-dribbble; }
.icon-skype:before { content: $fa-var-skype; }
.icon-foursquare:before { content: $fa-var-foursquare; }
.icon-trello:before { content: $fa-var-trello; }
.icon-female:before { content: $fa-var-female; }
.icon-male:before { content: $fa-var-male; }
.icon-gittip:before,
.icon-gratipay:before { content: $fa-var-gratipay; }
.icon-sun-o:before { content: $fa-var-sun-o; }
.icon-moon-o:before { content: $fa-var-moon-o; }
.icon-archive:before { content: $fa-var-archive; }
.icon-bug:before { content: $fa-var-bug; }
.icon-vk:before { content: $fa-var-vk; }
.icon-weibo:before { content: $fa-var-weibo; }
.icon-renren:before { content: $fa-var-renren; }
.icon-pagelines:before { content: $fa-var-pagelines; }
.icon-stack-exchange:before { content: $fa-var-stack-exchange; }
.icon-arrow-circle-o-right:before { content: $fa-var-arrow-circle-o-right; }
.icon-arrow-circle-o-left:before { content: $fa-var-arrow-circle-o-left; }
.icon-toggle-left:before,
.icon-caret-square-o-left:before { content: $fa-var-caret-square-o-left; }
.icon-dot-circle-o:before { content: $fa-var-dot-circle-o; }
.icon-wheelchair:before { content: $fa-var-wheelchair; }
.icon-vimeo-square:before { content: $fa-var-vimeo-square; }
.icon-turkish-lira:before,
.icon-try:before { content: $fa-var-try; }
.icon-plus-square-o:before { content: $fa-var-plus-square-o; }
.icon-space-shuttle:before { content: $fa-var-space-shuttle; }
.icon-slack:before { content: $fa-var-slack; }
.icon-envelope-square:before { content: $fa-var-envelope-square; }
.icon-wordpress:before { content: $fa-var-wordpress; }
.icon-openid:before { content: $fa-var-openid; }
.icon-institution:before,
.icon-bank:before,
.icon-university:before { content: $fa-var-university; }
.icon-mortar-board:before,
.icon-graduation-cap:before { content: $fa-var-graduation-cap; }
.icon-yahoo:before { content: $fa-var-yahoo; }
.icon-google:before { content: $fa-var-google; }
.icon-reddit:before { content: $fa-var-reddit; }
.icon-reddit-square:before { content: $fa-var-reddit-square; }
.icon-stumbleupon-circle:before { content: $fa-var-stumbleupon-circle; }
.icon-stumbleupon:before { content: $fa-var-stumbleupon; }
.icon-delicious:before { content: $fa-var-delicious; }
.icon-digg:before { content: $fa-var-digg; }
.icon-pied-piper:before { content: $fa-var-pied-piper; }
.icon-pied-piper-alt:before { content: $fa-var-pied-piper-alt; }
.icon-drupal:before { content: $fa-var-drupal; }
.icon-joomla:before { content: $fa-var-joomla; }
.icon-language:before { content: $fa-var-language; }
.icon-fax:before { content: $fa-var-fax; }
.icon-building:before { content: $fa-var-building; }
.icon-child:before { content: $fa-var-child; }
.icon-paw:before { content: $fa-var-paw; }
.icon-spoon:before { content: $fa-var-spoon; }
.icon-cube:before { content: $fa-var-cube; }
.icon-cubes:before { content: $fa-var-cubes; }
.icon-behance:before { content: $fa-var-behance; }
.icon-behance-square:before { content: $fa-var-behance-square; }
.icon-steam:before { content: $fa-var-steam; }
.icon-steam-square:before { content: $fa-var-steam-square; }
.icon-recycle:before { content: $fa-var-recycle; }
.icon-automobile:before,
.icon-car:before { content: $fa-var-car; }
.icon-cab:before,
.icon-taxi:before { content: $fa-var-taxi; }
.icon-tree:before { content: $fa-var-tree; }
.icon-spotify:before { content: $fa-var-spotify; }
.icon-deviantart:before { content: $fa-var-deviantart; }
.icon-soundcloud:before { content: $fa-var-soundcloud; }
.icon-database:before { content: $fa-var-database; }
.icon-file-pdf-o:before { content: $fa-var-file-pdf-o; }
.icon-file-word-o:before { content: $fa-var-file-word-o; }
.icon-file-excel-o:before { content: $fa-var-file-excel-o; }
.icon-file-powerpoint-o:before { content: $fa-var-file-powerpoint-o; }
.icon-file-photo-o:before,
.icon-file-picture-o:before,
.icon-file-image-o:before { content: $fa-var-file-image-o; }
.icon-file-zip-o:before,
.icon-file-archive-o:before { content: $fa-var-file-archive-o; }
.icon-file-sound-o:before,
.icon-file-audio-o:before { content: $fa-var-file-audio-o; }
.icon-file-movie-o:before,
.icon-file-video-o:before { content: $fa-var-file-video-o; }
.icon-file-code-o:before { content: $fa-var-file-code-o; }
.icon-vine:before { content: $fa-var-vine; }
.icon-codepen:before { content: $fa-var-codepen; }
.icon-jsfiddle:before { content: $fa-var-jsfiddle; }
.icon-life-bouy:before,
.icon-life-buoy:before,
.icon-life-saver:before,
.icon-support:before,
.icon-life-ring:before { content: $fa-var-life-ring; }
.icon-circle-o-notch:before { content: $fa-var-circle-o-notch; }
.icon-ra:before,
.icon-rebel:before { content: $fa-var-rebel; }
.icon-ge:before,
.icon-empire:before { content: $fa-var-empire; }
.icon-git-square:before { content: $fa-var-git-square; }
.icon-git:before { content: $fa-var-git; }
.icon-hacker-news:before { content: $fa-var-hacker-news; }
.icon-tencent-weibo:before { content: $fa-var-tencent-weibo; }
.icon-qq:before { content: $fa-var-qq; }
.icon-wechat:before,
.icon-weixin:before { content: $fa-var-weixin; }
.icon-send:before,
.icon-paper-plane:before { content: $fa-var-paper-plane; }
.icon-send-o:before,
.icon-paper-plane-o:before { content: $fa-var-paper-plane-o; }
.icon-history:before { content: $fa-var-history; }
.icon-genderless:before,
.icon-circle-thin:before { content: $fa-var-circle-thin; }
.icon-header:before { content: $fa-var-header; }
.icon-paragraph:before { content: $fa-var-paragraph; }
.icon-sliders:before { content: $fa-var-sliders; }
.icon-share-alt:before { content: $fa-var-share-alt; }
.icon-share-alt-square:before { content: $fa-var-share-alt-square; }
.icon-bomb:before { content: $fa-var-bomb; }
.icon-soccer-ball-o:before,
.icon-futbol-o:before { content: $fa-var-futbol-o; }
.icon-tty:before { content: $fa-var-tty; }
.icon-binoculars:before { content: $fa-var-binoculars; }
.icon-plug:before { content: $fa-var-plug; }
.icon-slideshare:before { content: $fa-var-slideshare; }
.icon-twitch:before { content: $fa-var-twitch; }
.icon-yelp:before { content: $fa-var-yelp; }
.icon-newspaper-o:before { content: $fa-var-newspaper-o; }
.icon-wifi:before { content: $fa-var-wifi; }
.icon-calculator:before { content: $fa-var-calculator; }
.icon-paypal:before { content: $fa-var-paypal; }
.icon-google-wallet:before { content: $fa-var-google-wallet; }
.icon-cc-visa:before { content: $fa-var-cc-visa; }
.icon-cc-mastercard:before { content: $fa-var-cc-mastercard; }
.icon-cc-discover:before { content: $fa-var-cc-discover; }
.icon-cc-amex:before { content: $fa-var-cc-amex; }
.icon-cc-paypal:before { content: $fa-var-cc-paypal; }
.icon-cc-stripe:before { content: $fa-var-cc-stripe; }
.icon-bell-slash:before { content: $fa-var-bell-slash; }
.icon-bell-slash-o:before { content: $fa-var-bell-slash-o; }
.icon-trash:before { content: $fa-var-trash; }
.icon-copyright:before { content: $fa-var-copyright; }
.icon-at:before { content: $fa-var-at; }
.icon-eyedropper:before { content: $fa-var-eyedropper; }
.icon-paint-brush:before { content: $fa-var-paint-brush; }
.icon-birthday-cake:before { content: $fa-var-birthday-cake; }
.icon-area-chart:before { content: $fa-var-area-chart; }
.icon-pie-chart:before { content: $fa-var-pie-chart; }
.icon-line-chart:before { content: $fa-var-line-chart; }
.icon-lastfm:before { content: $fa-var-lastfm; }
.icon-lastfm-square:before { content: $fa-var-lastfm-square; }
.icon-toggle-off:before { content: $fa-var-toggle-off; }
.icon-toggle-on:before { content: $fa-var-toggle-on; }
.icon-bicycle:before { content: $fa-var-bicycle; }
.icon-bus:before { content: $fa-var-bus; }
.icon-ioxhost:before { content: $fa-var-ioxhost; }
.icon-angellist:before { content: $fa-var-angellist; }
.icon-cc:before { content: $fa-var-cc; }
.icon-shekel:before,
.icon-sheqel:before,
.icon-ils:before { content: $fa-var-ils; }
.icon-meanpath:before { content: $fa-var-meanpath; }
.icon-buysellads:before { content: $fa-var-buysellads; }
.icon-connectdevelop:before { content: $fa-var-connectdevelop; }
.icon-dashcube:before { content: $fa-var-dashcube; }
.icon-forumbee:before { content: $fa-var-forumbee; }
.icon-leanpub:before { content: $fa-var-leanpub; }
.icon-sellsy:before { content: $fa-var-sellsy; }
.icon-shirtsinbulk:before { content: $fa-var-shirtsinbulk; }
.icon-simplybuilt:before { content: $fa-var-simplybuilt; }
.icon-skyatlas:before { content: $fa-var-skyatlas; }
.icon-cart-plus:before { content: $fa-var-cart-plus; }
.icon-cart-arrow-down:before { content: $fa-var-cart-arrow-down; }
.icon-diamond:before { content: $fa-var-diamond; }
.icon-ship:before { content: $fa-var-ship; }
.icon-user-secret:before { content: $fa-var-user-secret; }
.icon-motorcycle:before { content: $fa-var-motorcycle; }
.icon-street-view:before { content: $fa-var-street-view; }
.icon-heartbeat:before { content: $fa-var-heartbeat; }
.icon-venus:before { content: $fa-var-venus; }
.icon-mars:before { content: $fa-var-mars; }
.icon-mercury:before { content: $fa-var-mercury; }
.icon-transgender:before { content: $fa-var-transgender; }
.icon-transgender-alt:before { content: $fa-var-transgender-alt; }
.icon-venus-double:before { content: $fa-var-venus-double; }
.icon-mars-double:before { content: $fa-var-mars-double; }
.icon-venus-mars:before { content: $fa-var-venus-mars; }
.icon-mars-stroke:before { content: $fa-var-mars-stroke; }
.icon-mars-stroke-v:before { content: $fa-var-mars-stroke-v; }
.icon-mars-stroke-h:before { content: $fa-var-mars-stroke-h; }
.icon-neuter:before { content: $fa-var-neuter; }
.icon-facebook-official:before { content: $fa-var-facebook-official; }
.icon-pinterest-p:before { content: $fa-var-pinterest-p; }
.icon-whatsapp:before { content: $fa-var-whatsapp; }
.icon-server:before { content: $fa-var-server; }
.icon-user-plus:before { content: $fa-var-user-plus; }
.icon-user-times:before { content: $fa-var-user-times; }
.icon-hotel:before,
.icon-bed:before { content: $fa-var-bed; }
.icon-viacoin:before { content: $fa-var-viacoin; }
.icon-train:before { content: $fa-var-train; }
.icon-subway:before { content: $fa-var-subway; }
.icon-medium:before { content: $fa-var-medium; }
