.form-31 {
  input[name="signingDate"] {
    width: 8rem !important;
  }
  input[type="text"] {
    min-width: 12rem;
  }
  .form-name-description {
    text-align: center;
    font-style: italic;
    font-size: 1.4rem;
  }
  .partners-table {
    .first-row {
      text-transform: uppercase;
      font-weight: bold;
    }
    td:nth-child(1) {
      min-width: 10rem;
    }
    td:nth-child(2) {
      padding-left: 3rem;
    }
    td:nth-child(3) {
      padding-left: 3rem;
    }
  }
  .contract-point {
    font-weight: bold;
  }
  .dichvu-wrapper {
    margin-top: -3rem;
    .checkbox-input-wrapper {
      label {
        font-style: italic;
      }
    }
  }
  .gtgt {
    display: inline-block;
  }
  .checkbox-input-wrapper {
    input {
      margin-right: 2rem;
    }
    label {
      font-weight: bold;
    }
  }
  .dichvu-table, .chatluong-dichvu-table {
    width: 100%;
    margin-bottom: 2rem;
    table {
      width: 100%;
      thead {
        tr th {
          background-color: #e1f0d9;
          padding: 4px;
          border: 1px solid #000;
          text-align: center;
        }
      }
      tbody {
        tr td {
          padding: 4px;
          text-align: center;
          .chi-tiet-dich-vu {
            width: 100%;
            min-width: 40rem;
          }
          input {
            width: 100%;
            border: none;
          }
          border: 1px solid #000;
          &.sum-input {
            font-weight: bold;
            text-align: center;
          }
        }
        tr td:nth-child(1) {
          text-align: center;
        }
        tr td:nth-child(5) {
          width: 20rem;
          text-align: right;
        }
        tr td:last-child {
          width: 10rem;
          button {
            margin: 0rem 1rem;
          }
        }
      }
    }
  }
  .chatluong-dichvu-table {
    tbody tr td:nth-child(1) {
      width: 10rem;
    }
    tbody tr td:nth-child(3) {
      width: 50rem;
    }
  }
  .sign-section {
    margin-top: 2rem;
    margin-bottom: 0rem;
    .sign-title, .signer-name, .signer-position {
      font-weight: bold;
      text-align: center;
    }
    .signer-name {
      margin-top: 5rem;
    }
  }
  .sign-title {
    margin-top: 5rem;
  }
  .fixed-content-section {
    .point {
      text-transform: uppercase;
      font-weight: bold;
    }
    .point-point {
      font-style: italic;
      font-weight: bold;
    }
    .list-abc {
      list-style: lower-alpha;
    }
    p {
      font-family: 'Montserrat', sans-serif;
    }
  }
  .submit-btn {
    text-align: center;
    .btn {
      font-size: 1.6rem;
    }
  }
}