.ticket-detail-page {
  text-align: left;
  max-width: 70%;
  margin: auto;
  margin-top: 2rem;
  .ticket-history {
    margin-top: 2rem;
    .history {
      margin-top: 1rem;
      &.comment {
        position: relative;
        .content {
          position: relative;
          border-radius: 10px;
          padding: 1rem;
          margin-top: 1rem;
          background-color: #FCFCFC;
          .delete-file  {
            position: absolute;
            top: 10px;
            right: 10px;
          }
          &:before {
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            display: block;
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent #FCFCFC transparent;
            top: -20px;
            left: 14px;
          }
          .file-loading-description {
            font-size: 1rem;
            font-style: italic;
          }
          .file-preview {
            width: 100%;
            position: relative;
            .reject-icon {
              margin: auto;
              height: 45rem;
              margin-top: -45rem;
              text-align: center;
              i {
                color: red;
                opacity: 0.3;
                font-size: 40rem;
                position: relative;
                animation: zoominsinglefeatured 1s ease-in-out;
              }
            }
            .notified-to  {
              position: absolute;
              top: 20px;
              left: 20px;
              background: rgba(0,0,0,0.6);
              padding: 1rem;
              border-radius: 1rem;
              color: #fff;
              font-size: 1.2rem;
              .signed {
                color: #5beb7d
              }
            }
          }
          .file-btns {
            .row {
              margin: 0;
            }
            button {
              font-size: 1.4rem;
            }
            .reject-btn {
              margin-left: 2rem;
            }
          }
          .signers {
            position: absolute;
            top: 0;
            left: -21rem;
            width: 20rem;
            max-width: 20rem;
            font-size: 1.2rem;
            .signer {
              background-color: #28a745;
              padding: 1rem;
              border-radius: 1rem;
              color: #fff;
              position: relative;
              margin-bottom: 1rem;
              font-size: 1rem;
              &:before {
                content: '';
                height: 0;
                width: 0;
                position: absolute;
                display: block;
                border-width: 10px;
                border-style: solid;
                border-color: transparent transparent  transparent #28a745;
                top: 22px;
                right: -20px;
              }
              &.rejecter {
                background-color: #dc3545;
                &:before {
                  border-color: transparent transparent  transparent #dc3545;
                }
              }
            }
          }
        }
        .feedback-table-42 {
          tr td {
            padding: 1rem;
          }
        }
      }
      .user-name {
        font-weight: bold;
      }
      .person {
        color: #851e3e;
        font-style: italic;
      }
      .task-content {
        color: #03396c;
        background-color: #faebd7;
        padding: 4px 10px;
        // border-radius: 10px;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
      }
      .task-status {
        font-weight: bold;
        &-1 {
          color: #7bc043;
        }
        &-0 {
          color: #fb5959;
        }
      }
      .action {
        font-weight: bold;
        color: #fb5959;
      }
      .time {
        color: #4e8dbb;
      }
      .edit-history-table {
        table, tr, td {
          border: 1px solid #4e8dbb;
          padding: 2px;
        }
      }
    }
  }
  .comment-box {
    margin-top: 2rem;
    background-color: #fff;
    padding: 1rem;
    .form-common {
      max-width: 100%;
      margin: 0px;
      .card {
        padding: 0px;
      }
    }
    .comment-composer {
      display: flex;
      flex-direction: column;
      position: relative;
      label {
        font-weight: bold;
      }
      textarea {
        outline: none;
      }
    }
    .comment-btn {
      margin-top: 1rem;
      font-size: 1.4rem;
    }
  }
  .ticket-body {
    background-color: #fff;
  }

  .salary-histories {
    background-color: #fff;
    margin-top: 1rem;
    padding: 2rem;
    .top {
      text-align: center;
      font-weight: bold;
    }
    table {
      margin: auto;
      margin-top: 1rem;
      thead tr {
        font-weight: bold;
      }
      td {
        border: 1px solid #eee;
        padding: 1rem;
      }
    }
  }
}

.ticket-detail-page .left-panel {
  .ticket-custom-notation {
    margin: 2rem 0rem;
    .error-message {
      font-size: 1.6rem;
    }
  }
  .ticket-status {
    .status {
      display: inline-block;
      padding: 1rem;
      font-size: 1.4rem;
      outline: none;
    }
    .next {
      display: inline-block;
      .fa {
        margin-left: 3rem;
        margin-top: 0.5rem;
        font-size: 3rem;
        vertical-align: middle;
        color: #6c757d;
      }
    }
  }
  .action-btn {
    background-color: #fff;
    margin-top: 2rem;
    padding: 1rem 2rem;
    .btn {
      background-color: #eee;
      border: 1px solid #aaa;
      font-size: 1.4rem;
      text-transform: uppercase;
      margin-right: 1rem;
    }
  }
  .ticket-body {
    margin-top: 2rem;
    padding: 2rem;
    .clone-btn {
      margin-bottom: 0rem;
      .btn {
        // background-color: #0392cf;
        color: #fff;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin: .5rem 0rem;
      }
    }
    .ticket-name {
      font-size: 2.4rem;
      font-weight: bold;
      color: #851e3e;
    }
    .ticket-data-wrapper {
      margin-top: 3rem;
      .ticket-data-row {
        margin-top: 2rem;
        &:after, &:before {
          display: table;
          clear: both;
          content: "";
        }
        &.highlight {
          .value {
            background-color: #0392cf;
            color: #fff;
          }
        }
        &.editted {
          .value {
            background-color: #eccb0a;
            color: #000;
          }
        }
        .label {
          font-weight: bold;
          // color: #854442;
        }
        .value {
          background-color: #eee;
          padding: 6px;
          border: 1px solid #aaa;
          border-radius: 5px;
          overflow-x: auto;
          &:focus {
            display: block;
            border-color: #66afe9;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
          }
          .table-wrapper {
            overflow-x: auto;
            padding: 1px;
            table {
              th, td {
                padding: 4px 10px;
                border: 1px solid #777;
              }
            }
          }
          .obj-standalone {
            margin-bottom: 1rem;
            border-bottom: 1px solid #bbb;
            .obj {
              border-bottom: 1px solid #f1e5e5;
            }
            .obj-value {
              padding-left: 2rem;
            }
            &:last-child {
              border: none;
            }
          }
        }
        
      }
    }
  }
}

.ticket-detail-page .right-panel {
  .title {
    font-weight: bold;
    color: #011f4b;
    i {
      font-size: 2rem;
      margin-left: 1rem;
    }
  }
  .user-info {
    font-size: 1.6rem;
    margin-top: 0.4rem;
  }
  .dropdown-divider {
    border-top: 1px solid #aaa;
    margin: 1rem 0rem;
  }
  .watchers {
    .remove-watcher-btn {
      font-size: 2rem;
      vertical-align: middle;
      cursor: pointer;
      margin-left: 2px;
      color: #fb5959;
    }
    .add-watcher-form {
      .watcher-email {
        label {
          display: none;
        }
        padding: 0;
        input {
          border: 1px solid #d2d2d2 !important;
          height: 30px;
          outline: none;
        }
      }
      .add-watcher-btn {
        font-size: 1.4rem;
        margin-left: 1rem;
        vertical-align: bottom;
      }
      .input-suggestions {
        .suggestions {
          ul {
            padding: 0px;
          }
          li {
            cursor: pointer;
            list-style-type: none;
            margin: 2px 0px;
            padding: 2px;
            font-style: italic !important;
            &:hover {
              background-color: #eee;
            }
          }
        }
      }
    }
  }
  .checklist {
    .task {
      box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      padding: 0rem;
      margin-top: 1rem;
      .left {
        padding: 1rem;
        background-color: #faebd7;
        .task-content {
          color: #03396c;
        }
        .task-assignee {
          .assignee-email {
            font-size: 1rem;
            font-style: italic;
          }
        }
        .task-deadline {
          font-size: 1rem;
          border-top: 1px solid #b3cde0;
          margin-top: 6px;
          padding-top: 4px;
          color: #ee4035;
        }
      }
      .right {
        background-color: #e6e6ea;
        text-align: center;
        vertical-align: middle;
        .checkbox-btn {
          margin: auto;
          height: 100%;
          label {
            height: 100%;
            width: 100%;
            cursor: pointer;
          }
          input {
            margin: auto;
            width: 20px;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
    .new-task {
      margin-top: 1rem;
      .btn {
        font-size: 1.4rem;
      }
    }
  }
}

.reject-ticket-popup {
  padding: 2rem;
  .swal2-title {
    font-size: 1.4em;
  }
  textarea {
    margin-top: 1rem !important; 
    margin-bottom: 1rem !important; 
  }
  .swal2-actions {
    margin-top: 0rem;
    button {
      font-size: 1.4rem;
      padding: 4px 10px;
    }
  }
}

.form-15-notation {
  margin: 0 !important;
  max-width: 1000px !important;
  .allowance-table {
    margin: auto;
    margin-bottom: 1rem;
    th {
      text-align: center;
      background-color: yellowgreen;
    }
    td, th {
      border: 1px solid #aaa;
      padding: 8px;
    }
  }
}

.ticket-detail-page-26, .ticket-detail-page-27 {
  .ticket-data-row {
    // font-family: 'Times New Roman', serif !important;
    .value {
      background-color: #fff !important;
      border: none !important;
      padding: 0px !important;
    }
  } 
}

.ticket-detail-page-20 {
  .ticket-data-wrapper {
    .table-wrapper {
      table {
        table-layout:fixed;
        width:100%;
        th, td {
          font-size: 1rem;
          word-wrap: break-word;
        }
      }
    }
  }
}