.interns-page {
  margin: auto;
  margin-top: 1rem;
  font-size: 1.4rem;
  max-width: 90%;
  .action-btns-wrapper {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0rem 1rem;
    .register-btn  {
      margin-left: 2rem;
    }
    button {
      font-size: 1.4rem;
    }
  }
  .export-csv {
    text-align: right;
    transform: translateY(1rem);
    a {
      text-decoration: underline;
    }
  }
  .title {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bolds;
  }
  .interns-list {
    background-color: #fff;
    padding: 2rem;
  }
  .rt-thead {
    font-weight: bold;
  }
  .rt-tr-group {
    cursor: pointer;
  }

  .report-sum-salary {
    background-color: #fff;
    margin-top: 1rem;
    padding: 1rem;
    vertical-align: bottom;
    min-height: 420px;
    padding-top: 6rem;
    .report-title {
      color: #651e3e;
      font-weight: bold;
      text-align: center;
      margin-top: 3rem;
    }
  }

  .report-general-table {
    padding-top: 1rem;
    margin-top: 1rem;
    background-color: #fff;
    min-height: 420px;
    padding-bottom: 1rem;
    .report-title {
      text-align: center;
      margin-top: 1rem;
      font-weight: bold;
      color: #651e3e;
    }
    .table-wrapper {
      overflow: auto;
    }
    table {
      margin: auto;
      width: 95%;
      margin-top: 1rem;
      thead tr th {
        font-weight: 500;
        color: #011f4b;
        border: 1px solid #eee;
        text-align: center;
      }
      td {
        border: 1px solid #eee;
        padding: 1rem;
      }
    }
  }
}