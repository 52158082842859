.contract-32 {
  background-color: #fff;
  text-align: center;
  font-size: 1.8rem;
  font-family: 'Roboto', sans-serif;
  &.part-1, &.part-2 {
    background: #fff !important;
  }
  
  .can-cu {
    text-align: left;
    list-style-type: lower-alpha;
  }
  .dieu {
    text-align: left;
    .head-text {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .location-wrapper {
    text-align: left;
    width: 20rem;
    .location {
      list-style-type: lower-alpha;
    }
  }

  .rule-page {
    margin-top: 60rem;
  }
  .rule-list {
    list-style-type: decimal;
    text-align: left;
    li {
      margin-bottom: 1rem;
    }
  }

  .more-term {
    list-style-type: lower-alpha;
  }

  .text-normal {
    text-align: left;
  }

  .json-data-wrapper {
    background-color: #fff;
    overflow-x: scroll;
    table {
      th, td {
        padding: 8px;
        border: 1px solid #000;
      }
      thead > tr > th {
        font-weight: normal;
      }
    }
  }

}