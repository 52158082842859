.ticket-container {
  padding: 0rem 2rem;
  margin: auto;
}
.tickets-page {
  margin-top: 2rem;
  background-color: #fff;
  font-size: 1.4rem;
  .export-csv {
    padding-top: 1rem;
    text-align: right;
  }
  .side-menu {
    border-right: 1px solid #eee;
    font-size: 1.3rem;
    padding: 1rem;
    
    .nav-link {
      position: relative;
      &.active {
        background-color: #007bff;
        color: #fff;
        border-radius: 20px;
      }
      .ticket-cnt {
        margin-left: 0.5rem;
        min-width: 2.4rem;
        font-size: 14px;
        position: absolute;
        top: 4px;
        text-align: center;
        font-weight: bold;
        border-radius: 50%;
        background: red;
        color: white;
      }
    }
  }
  .body-content {
    padding: 1rem;
    text-align: center;
    .rt-tr-group {
      cursor: pointer;
    }
    .rt-thead {
      font-weight: bold;
    }
    // .action-btn {
    //   display: inline-block;
    //   margin-right: 2rem;
    //   border: 1px solid #aaa;
    //   padding: 4px;
    //   border-radius: 5px;
    //   cursor: pointer;
    //   vertical-align: text-top;
    //   margin-top: -6px;
    //   &.approve-btn {
    //     background-color: #007bffc4;
    //     color: #fff;
    //   }
    //   &.reject-btn {
    //     background-color: orange;
    //   }
    // }
  }


  .__react_component_tooltip {
    overflow: hidden;
    background-color: #e7eff6 !important;
    color: #333 !important;
    padding: 10px 20px !important;
    border: 1px solid #ddd !important;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    &.show {
      opacity: 0.99 !important;
    }
    .ticket-title {
      font-size: 1.5rem;
      font-weight: bold;
      color: #851e3e;
    }
    .latest-update {
      text-align: left;
      .his {
        margin: 4px 0rem;
        .user-name {
          font-weight: bold;
        }
        &:before {
          content: "-";
          position: absolute;
          margin-left: -8px;
        }
        .content {
          color: #007bffc4;
        }
      }
    }
  }
}