  .form-1 {
  max-width: 700px;
  margin: auto;
  margin-top: 2rem;
  font-size: 1.6rem !important;
  .sub-label {
    margin-right: 3rem;
    margin-top: 5px;
    width: 10rem;
  }
  .my-date-picker {
    font-size: 16px !important;
    .react-datepicker-popper {
      min-width: 400px;
      font-size: 16px !important;
    }
  }
  .title {
    font-size: 3.6rem;
    &.guest {
      font-size: 2.5rem;
    }
  }
  .sub {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
  .card {
    padding: 3rem;
    margin-top: 1rem;
  }
  select {
    height: 30px;
  }
  .business-allowance {
    .number {
      color: red;
      font-weight: bold;
    }
    .allowance-table {
      margin-bottom: 1rem;
      th {
        text-align: center;
        background-color: yellowgreen;
      }
      td, th {
        border: 1px solid #aaa;
        padding: 4px;
      }
    }
  }
  
  .colleagues {
    .add-colleague-form {
      margin-top: 2rem;
    }
    .add-colleague {
      display: inline-block;
      vertical-align: middle;
      padding-left: 0px;
    }
    .btn {
      vertical-align: middle;
      cursor: pointer;
      font-size: 1.4rem;
      margin-left: 1rem;
    }
    .inputed-colleagues {
      display: inline-block;
      .inputed-colleague {
        display: inline-block;
        margin-top: 0.6rem;
      }
      .remove-colleague-btn {
        cursor: pointer;
      }
    }
  }

  .room-selector-row {
    input {
      width: 5rem;
    }
    label {
      margin-left: 1rem;
    }
  }

  .guests {
    .btn {
      vertical-align: middle;
      cursor: pointer;
      font-size: 1.4rem;
      margin-left: 1rem;
    }
    .add-guest-form {
      .guest-name {
        width: 45%;
      }
      .guest-id-no {
        width: 40%;
        margin-left: 1rem;
      }
      .add-btn {
        float: right;
        cursor: pointer;
      }

    }
    .inputed-guests {
      display: inline-block;
      .inputed-guest {
        display: inline-block;
        margin-bottom: 1rem;
      }
      .remove-guest-btn {
        float: right;
        cursor: pointer;
      }
    }
  }

  .oversea-trip-note {
    color: red;
    font-size: 1.5rem;
    animation: shrink 1s ease-in-out infinite;
    font-weight: bold;
    a {
      color: red;
      margin-left: 4px;
      text-decoration: underline;
    }
  }
}

.form-2 {
  max-width: 1200px !important;
  .overtime-form-wrapper {
    margin-top: 2rem;
  }
  .instruction {
    margin: auto;
    margin-top: 2rem;

    .highlighted {
      font-weight: bold;
      font-size: 2.4rem;
      color: #e20404;
      text-align: center;
      text-transform: uppercase;
    }
    &-wrap {
      position: sticky;
      top: 2rem;
    }
    .calendar {
        ul {
          margin-top: 4px;
        }
    }
    ul {
      list-style-type: none;
      margin-left: -18px;
      margin-top: 2rem;
      li {
        font-size: 1.6rem;
        a {
          color: #2C2E2F;
          &:hover {
            color: #0070BA;
            text-decoration: underline;
          }
        }
        &:before {
          content: "-";
          position: absolute;
          margin-left: -20px;
        }
      }
    }
  }
  .overtime-form-wrapper {
    margin: auto;
    margin-top: 2rem;
    
    .title {
      font-size: 3rem;
      text-transform: uppercase;
    }
    
    .date-picker, .time-picker {
      display: inline-block;
    }
    .time-picker {
      margin-left: 2rem;
    }
  
    .result {
      .top {
        text-align: center;
        font-weight: bold;
      }
      table {
        font-size: 1.4rem;
      }
    }
  
    .duration {
      .value {
        font-weight: bold;
      }
    }
  
    .dates-selector {
      .date-selector {
        margin-top: 1rem;
        border-top: 1px solid #eee;
        padding-top: 1rem;
        .start-time, .end-time, .purpose, .payment-type {
          margin-left: 1rem;
        }
        .purpose {
          textarea {
            height: 30px;
            font-size: 1.4rem;
          }
          
        }
        .duration {
          display: inline-block;
          padding-top: 4px;
          margin-left: 4px;
        }
      }
      button {
        border: 1px solid #aaa;
        margin-left: 4px;
        cursor: pointer;
        font-weight: bold;
      }
      .my-date-picker {
        font-size: 1.4rem;
        height: 30px;
        // width: 100px ;
      }
      .my-time-picker {
        input {
          height: 30px;
          width: 100px;
          border-radius: 0px;
        }
      }
      select {
        height: 30px;
        font-size: 1.4rem;
        border-radius: 0px;
        border: 1px solid #00000033;
        background-color: #fff;
      }
    }
  }
}

.form-3, .form-28 {
  text-align: left;
  .description {
    padding: 1rem;
    color: red;
    text-align: center;
    font-weight: bold;
  }
  .extra-form {
    label {
      text-align: left;
    }
  }
  .add-admin-to-watchers, .other-infor-description {
    background-color: #fff;
      table {
        th, td {
          border: 1px solid #aaa;
          padding: 6px;
        }
      }
  }
}

.form-4 {
  margin-top: 2rem;
  text-align: left;
  .description {
    padding: 1rem;
    text-align: center;
    .title {
      color: red;
      font-weight: bold;
    }
    .table-wrapper {
      table {
        th, td {
          border: 1px solid #aaa;
          padding: 6px;
        }
      }
    }
  }
  .title {
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 2rem;
  }
  .extra-form {
    .card {
      border: none;
    }
    label {
      text-align: left;
    }
  }
}

.form-7 {
  margin-top: 2rem;
  .register-contract-wrapper {
    margin: auto;
    max-width: 700px;
  }
}
.form-7, .form-3, .form-22, .form-28, .form-34 {
  .uq-table {
    table {
      margin: auto;
      margin-bottom: 2rem;
    }
    td {
      padding: 1rem;
      border: 1px solid #ccc;
      .copy-to-clipboard {
        cursor: pointer;
      }
    }
  }
}
.form-8 {
  margin-top: 2rem;
  max-width: 700px;
}
.form-13 {
  .relatives {
    .relative {
      padding: 1.5rem;
      background-color: blanchedalmond;
      border: 1px solid #fed766;
      margin-bottom: 2rem;
    }
  }
}
.form-19 {
  .allowance-table {
    margin-bottom: 1rem;
    th {
      text-align: center;
      background-color: yellowgreen;
    }
    td, th {
      border: 1px solid #aaa;
      padding: 4px;
    }
  }
}
.form-21 {
  .reopen-form {
    margin-top: 5rem;
    background-color: #b3cde0;
    padding: 2rem;
  }
}
.print-page-form-21 {
  .print-btn-wrapper {
    width: 100%;
    text-align: center;
    .print-btn, .back-btn {
      margin: auto;
      margin-top: 4rem;
      font-size: 3rem !important;
    }
  }
}

.form-29 {
  max-width: 50%;
  .period-selector {
    background: #ddd;
  }
  .reason-input {
    width: 47rem;
    input {
      width: 40rem;
    }
  }
  .btns {
    button {
      font-size: 2rem;
      margin: 0rem 1rem;
    }
  }
}

.form-33 {
  select {
    min-height: 3rem !important;
  }
}

.form-36 {
  .checkbox-input-wrapper {
    label {
      margin-left: 4px;
    }
  }
}

.form-40 {
  table {
    tr {
      td {
        border: 1px solid #000;
        padding: 5px;
      }
    }
  }
}