.breadcrumb {
  font-weight: bold;
  font-size: 1.6rem;
  background-color: #eeeeee;
  padding: 7.5px 0px;
  &-node {
    display: inline-block;
  }
  .navigator-icon {
    display: inline-block;
    margin: 0px 4px;
  }
  a {
    color:#0070BA !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
.article-home {
  margin-top: 1rem;
}
.article-wrapper {
  margin-top: 1rem;
  background-color: #fff;
  border: 1px solid #aaaaaa5c;
  .leaf-wrapper {
    .title {
      padding: 2rem;
      font-size: 3rem;
      color: #0070BA;
      text-align: center;
      font-weight: 500;
      background-color: bisque;
    }
    .body {
      margin-top: 3rem;
      padding: 2rem;
      table {
        td {
          border: 1px solid #aaa;
        }
      }
    }
  }
  .children-wrapper {
    padding: 1rem;
    ul {
      list-style-type: none;
      margin-left: -18px;
      li {
        font-size: 1.6rem;
        a {
          color: #2C2E2F;
          &:hover {
            color: #0070BA;
            text-decoration: underline;
          }
        }
        &:before {
          content: "-";
          position: absolute;
          margin-left: -20px;
        }
      }
    }
  }
  a {
    color: $yellow;
    &:hover {
      text-decoration: none;
    }
  }
  
}

.page-article332 {
  .title {
    color: #ff0200 !important;
    background-color: #fff !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
  } 
  .article-wrapper .leaf-wrapper .body table td {
    border: none !important;
  }
  table {
    img {
      width: 500px;
      height: 333px;
    }
  }
}
