.ask-me-anything-page {
  width: 70%;
  margin: auto;
  .token-save {
    padding-bottom: 5rem;
    textarea {
      color: red;
      font-weight: bold;
      font-size: 2.4rem;
      padding-bottom: 0rem;
    }
  }
  ul {
    list-style-type: none;
    margin-left: -22px;
    li {
      font-size: 15px;
      &:before {
        content: "-";
        position: absolute;
        margin-left: -10px;
      }
    }
  }
  .form-divider-text {
    padding: 0rem !important;
    margin: 0rem 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .title {
      background-color: #fe9c8f;
      padding: 1rem;
      text-align: center;
      color: #333;
      font-size: 1.6rem;
      font-weight: bold;
    }
    .description {
      background-color: #fff;
      padding: 1rem;
      text-align: left;
    }
  }
  .menu-wrapper {
    background: #fff;
    text-align: left;
    padding: 0rem;
    .menu-id {
      cursor: pointer;
      padding: 1rem;
      &.active {
        background-color: #04AA6D; 
        color: white;
      }
      &:hover {
        // background: #ccc;
      }
    }
  }
  .inbox {
    .inbox-btn {
      margin-bottom: 1rem;
      background-color: #fff;
      text-align: center;
      border-radius: 2rem;
      cursor: pointer;
      &.active {
        background-color: #04AA6D; 
        color: #fff;
      }
    }
  }
  .form-common {
    max-width: 100%;
    padding: 0rem;
    margin-top: 0rem;
    label {
      text-align: left;
    }
    .textarea-description {
      text-align: left;
    }
  }
  .conversation {
    background-color: #fff;
    margin-bottom: 2rem;
    margin-right: 1rem;
    border-radius: 1rem;
    &.archived {
      filter: grayscale(1);
    }
    &-header {
      background-color: #005b96;
      color: #fff;
      padding: 4px;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      .last-updated {
        margin-top: 4px;
      }
      .token {
        text-align: center;
        font-size: 2rem;
        font-weight: bold;
      }
      .archive-btn {
        float: right;
        margin-right: 5px;
      }
    }
    &-messages {
      padding: 1rem;
      .message {
        background-color: #fff;
        margin-bottom: 1rem;
        width: 50%;
        &.mine {
          text-align: right;
          margin-left: 50%;
          .text {
            background-color: #0099fe;
            color: #fff;
          }
        }
        .user {
          .email {
            font-style: italic;
            font-size: 1.2rem;
          }
          .time {
            font-style: italic;
            font-size: 1.2rem;
            color: #f37736;
          }
        }
        .text {
          background-color: #e3e6ea;
          padding: 4px 10px;
          border-radius: 2rem;
          text-align: left;
        }
        .file {
          margin-top: 1rem;
        }
      }
    }
    .reply-box {
      border-top: 1px solid #e3e6ea;
      .my-dropzone {
        padding: 1rem !important;
        margin-top: -1rem;
      }
      .textarea-wrapper {
        width: 100%;
        padding: 1rem;
      }
      textarea {
        width: 100%;
        min-height: 6rem;
      }
      .submit-btn {
        margin-top: 1rem;
      }
      .btn-primary {
        font-size: 1.1rem !important; 
        border-radius: 2rem;
      }
    }
  }
  .go-back-btn {
    margin-bottom: 5px;
  }
  .copy-to-clipboard {
    font-size: 1.2rem;
    position: relative;
    margin-bottom: 2rem;
    margin-top: -8rem;
    margin-left: 2rem;
    cursor: pointer;
  }
  .searchbox-wrapper {
    display: flex;
    padding-right: 1rem;
    .fa {
      transform: translate(34px, 4px);
      color: #333;
    }
    input {
      height: 2.6rem;
      font-size: 1.2rem;
      color: #000;
      background-color: #024063;
      border: 1px solid #ccc !important;
      border-radius: 2rem;
      outline: none !important;
      box-shadow: none;
      width: 100%;
      padding-left: 3rem;
    } 
  }
  .radio-title {
    font-weight: 500 !important;
    color: #854442 !important;
  }
}