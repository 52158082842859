.register-leave-modal {
  .popup {
    width: 50%;
    height: 80rem;
    // background-color: #fff6e9;
    background: #eee;
    padding: 2rem;
    // border: 2px solid #251e3e;
  }
  .title {
    font-size: 2rem;
    text-transform: uppercase;
    color: #037bff;
    font-weight: bold;
  }
  .register-leave-form {
    text-align: left;
  }
  .date-selector {
    width: 22rem;
  }
  .period-selector {
    width: 16rem;
  }
  .close-btn {
    margin-top: 2rem;
    cursor: pointer;
  }
}