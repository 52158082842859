.new-task-modal {
  .popup {
    width: 500px;
    background-color: #fff6e9;
    padding: 2rem;
    // border: 2px solid #251e3e;
  }
  .title {
    font-size: 2rem;
    text-transform: uppercase;
    color: #037bff;
    font-weight: bold;
  }
  .form-common {
    .form-group {
      background-color: #fff6e9;
      padding: 0rem;
      border: none;
      text-align: left;
      margin-top: 1rem;
    }
    .submit-btn {
      text-align: center;
      margin: auto;
      .btn-secondary {
        margin-left: 3rem;
      }
      button {
        font-size: 1.5rem;
      }
    }
  }
}