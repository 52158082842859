.approve-center-page {
  margin: auto;
  margin-top: 2rem;
  font-size: 1.4rem;
  padding: 0px 2rem;

  .title {
    font-size: 3rem;
    color: #ee4035;
    text-transform: uppercase;
    text-align: center;
  }

  .actions {
    padding-left: 1rem;
    .btns {
      margin-top: 1rem;
      .m-btn {
        display: inline-block;
        margin-right: 2rem;
        border: 1px solid #aaa;
        padding: 4px 10px;
        border-radius: 5px;
        cursor: pointer;
        vertical-align: text-top;
        margin-top: -6px;
        &.approve-btn {
          background-color: #007bffc4;
          color: #fff;
        }
        &.reject-btn {
          background-color: orange;
        }
      }
    }
  }

  .approve-center {
    background-color: #fff;
    margin-top: 1rem;
    padding: 1rem;
  }

  .side-menu {
    border-right: 1px solid #eee;
    font-size: 1.3rem;
    padding: 1rem;
    .nav-link {
      &.active {
        background-color: #007bff;
        color: #fff;
        border-radius: 20px;
      }
    }
  }
  .body-content {
    padding: 1rem;
    text-align: center;
    .rt-tr-group {
      cursor: pointer;
    }
    .rt-thead {
      font-weight: bold;
    }
    .obj-standalone {
      margin: auto; 
      margin-bottom: 1rem;
      border: 1px solid #aaa;
      border-radius: 10px;
      border-bottom: 1px solid #bbb;
      .obj-value {
        padding-left: 2rem;
      }
    }
    .action-btn {
      display: inline-block;
      margin-right: 2rem;
      border: 1px solid #aaa;
      padding: 4px;
      border-radius: 5px;
      cursor: pointer;
      vertical-align: text-top;
      margin-top: -6px;
      &.approve-btn {
        background-color: #007bffc4;
        color: #fff;
      }
      &.reject-btn {
        background-color: orange;
      }
    }
  }
}