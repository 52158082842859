.contract-detail-page-wrapper {
  padding-top: 2rem;
  .top-section {
    max-width: 50%;
    margin: auto;
    .btn {
      font-size: 1.6rem;
    }
    .action-btn {
      background-color: #fff;
      margin-top: 2rem;
      padding: 1rem 2rem;
      .btn {
        background-color: #eee;
        border: 1px solid #aaa;
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-right: 1rem;
      }
    }
  }
}

.contract-30 {
  float:none !important;
  color:  #000;
  font-weight: 500;
  line-height: 2em;
  font-size: 1.348rem;
  padding: 0rem 3rem;
  max-width: 50%;
  background-color: #fff;
  .cover-page {
    font-size: 1.4rem;
    table {
      width: 100%;
    }
  }

  .dichvu-result {
    .dichvu-text {
      margin-left: 1rem;
      font-weight: bold;
    }
  }

  .fixed-content-section {
    font-size: 1.3rem;
  }
}