header {
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  .navbar {
    background-color: #fff !important;
    .nav-item {
      font-size: 1.6rem;
      margin-right: 1rem;
      position: relative;
      .badge {
        min-width: 2rem;
        font-size: 12px;
        position: absolute;
        top: 0px;
        right: -12px;
        padding: 2px 4px;
        border-radius: 50%;
        background: red;
        color: white;
      }
    }
    .dropdown-item {
      font-size: 2rem;
    }
  }
  .login-section {
    display: inline-block;
    .avatar {
      img {
        border-radius: 50%;
        margin-right: 4px;
        width: 24px;
      }
    }
    a.username {
      color: #2C2E2F ;
    }
    .login-url {
      display: inline-block;
      font-size: 1.6rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      // margin-right: 1rem;
      span {
        color: #4e2828;
        white-space: nowrap;
        overflow: hidden;
        max-width: 40rem;
        text-overflow: ellipsis;
        float: left;
        // margin-right: 1rem;
      }
    }
  }
}
