@include min-screen(1200px) {
}
@media screen and (orientation: portrait) {
}
@include max-screen(736px) {
  header {
    .navbar .nav-item {
      font-size: 1.4rem !important;
    }
    .login-section {
      .login-url {
        font-size: 1.4rem !important;
      }
    }
  }
  .main-home {
    margin-top: 1rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .carousel-caption {
    display: inline !important;
    padding-bottom: 0px !important;
    font-size: 1.2rem !important;
    .noti-right {
      .text {
        font-size: 1.2rem !important;
      }
      .noti-url {
        font-size: 1.2rem;
      }
      .created-at {
        font-size: 1.1rem !important;
        .date {
          font-size: 1.2rem !important;
        }
      }
    }
  }
  .search-box-wrapper {
    margin-bottom: 0px;
    .advanced-search {
      margin-left: 0%;
      transform: none;
    }
  }
  .react-autosuggest__input {
    width: 100%;
  }
  .react-autosuggest__suggestions-container--open {
    width: 100%;
    transform: none;
  }
  .questions-wrapper {
    width: 100% !important;
  }
  .first-level-node {
    padding: 1rem !important;
    min-height: 20rem !important;
    height: 20rem !important;
    margin-top: 1rem;
    .node-name {
      font-size: 1.6rem;
      margin-top: 1rem;
    }
    .node-description {
      margin-top: 1rem;
    }
  }
  input[type="radio"] {
    margin-right: 1rem;
    margin-top: -0.4rem;
    vertical-align: middle;
    cursor: pointer;
  }
  .breadcrumb {
    font-size: 1.4rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .article-home {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 0px;
    .article-wrapper {
      margin-top: 0px;
      ul {
        margin-left: -24px;
        li {
          font-size: 1.4rem;
          &:before {
            margin-left: -16px;
          }
        }
      }
      .title {
        font-size: 2rem;
      }
      .body {
        margin-top: 1rem;
        font-size: 1.4rem;
      }
      .leaf-wrapper {
        padding: 1rem;
      }
    }
  }
  .error-message-page {
    .error-message {
      font-size: 1.6rem;
    }
  }
  .form-common {
    margin-top: 1rem;
    .top {
      .title {
        font-size: 1.6rem;
      }
    }
  }
  .overtime-form {
    .dates-selector {
      .purpose, .payment-type, .btns {
        margin-top: 10px;
      }
      .purpose {
        margin-left: 0px !important;
      }
    }
  }
  .ticket-detail-page {
    font-size: 1.2rem;
    max-width: 95%;
    .signers {
      position: relative !important;
      top: 1rem !important;
      left: 0 !important;
      max-width: 100% !important;
      .signer {
        &:before {
          display: none !important;
        }
      }
    }
    .left-panel {
      .ticket-body {
        padding: 1rem !important;
      }
      .ticket-status {
        .next {
          display: none !important;
        }
      }
      .action-btn  {
        .btn {
          font-size: 1.2rem !important;
        }
      }
      .comment-box {
        padding: 1rem;
      }
    }
    .right-panel {
      margin-top: 2rem;
      .user-info {
        font-size: 1.2rem;
      }
    }
  }
  .ask-me-anything-page {
    width: 100%;
    .form-divider-text {
      max-width: 100% !important;
    }
    .form-common {
      margin-top: 2rem;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}
 
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}
 
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
 
@page {
  size: auto;
  margin: 20mm;
}
