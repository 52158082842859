.swal2-container {
  .tax-code-warning-popup {
    #swal2-content {
      text-align: left !important;
    }
  }
}

.swal-full-width {
  width: 95vw !important;
  // height: 95vh;
}