.report-feedback-code-page {
  margin: auto;
  max-width: 90%;
  .explaination {
    table {
      td {
        border: 1px solid #eee;
        padding: 2px;
      }
    }
  }
  .chart-wrapper {
    width: 100%;
    align-items: flex-start;
    .ant-space-item {
      width: 100%;
    }
  }
}
