
.contract-wrapper {
  width: 100%;
  max-width: 1000px !important;
  margin: auto;
  text-align: center;
  .questions-wrapper {
    margin: auto;
    width: 70%;
    .question-wrapper {
      margin-top: 2rem;
      .__react_component_tooltip {
        overflow: hidden;
        background-color: #e7eff6 !important;
        color: #333 !important;
        padding: 10px !important;
        font-size: 1.5rem;
        border: 1px solid #999 !important;
        &.show {
          opacity: 0.99 !important;
        }
        .new {
          font-weight: bold;
          color: #851e3e;
          animation: shake 1s ease-in-out infinite;
        }
      }
    }
    .top {
      border-radius: 10px;
      margin-bottom: 1rem;
      .title {
        background-color: #0070BA;
        color: #fff;
        padding: 1rem;
      }
      .description {
        text-align: left;
        label {
          padding: 1rem;
        }
        background-color: #fff;
        padding: 1rem;
      }
    }
    .content {
      text-align: left;
      .question {
        text-align: center;
        font-size: 1.6rem;
        background-color: #0070BA;
        color: #fff;
        padding: 1rem;
      }
      .choices {
        background-color: #fff;
        padding: 2rem;
      }
    }
  }
  .copy-to-clipboard {
    border: 1px solid #0070b9;
    width: 200px;
    text-align: center;
    margin: auto;
    cursor: pointer;
    padding: 4px 10px;
    background-color: #eee;
  }
}
.found-result {
  margin-top: 2rem;
}
